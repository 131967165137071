import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Gleap from "gleap";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { RootState } from "../Store/Store";
import { defaultRedirect } from "../utils/defaultRedirect";

export const UserGuard = (props: any) => {
  const { children } = props;
  const isAuthenticated = useSelector((state: RootState) => state.auth?.isAuthenticated);
  const [checked, setChecked] = useState(false);
  const router = useRouter();
  const permissions = useSelector((state: RootState) => state.auth.user.permissions);
  const role = useSelector((state: RootState) => state.auth.user?.profile?.role_sc);
  const handleRedirect = () => {
    const link = defaultRedirect(permissions, role);
    router.push(link || "/");
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (!isAuthenticated) {
      setChecked(true);
    } else {
      handleRedirect();
    }
  }, [isAuthenticated, router.isReady]);

  useEffect(() => {
    Gleap.showFeedbackButton(false);
  }, []);

  if (!checked) {
    return null;
  }

  return children;
};

UserGuard.propTypes = {
  children: PropTypes.node,
};

UserGuard.defaultProps = {
  children: undefined,
};
